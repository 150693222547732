import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Grid } from "@mui/material";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Header from "./Header";
import MainFeaturedPost from "./MainFeaturedPost";
import Main from "./Main";
import Footer from "./Footer";
import { blueDeep, blueMedium, orange } from "../utils/colors";

const mainFeaturedPost = {
  title: "Qu'est-ce que le décret tertiaire ?",
  subtitle: "Comment l'appliquer",
  description:
    "Mieux comprendre les tenants et aboutissants du décret tertiaire, tel est l'objectif de ce site ! Nous voulons aider les professionnels sur les démarches à suivre pour demeurer dans le respect des lois.",
  image: "https://source.unsplash.com/random",
  imageText: "main image description",
};

const theme = createTheme({
  palette: {
    primary: {
      main: blueMedium,
    },
    secondary: {
      main: blueDeep,
    },
    info: {
      main: orange,
      contrastText: "white",
    },
  },
  typography: {
    fontFamily: ["Arial"].join(","),
  },
});

export default function Blog() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Header title="Blog" />
      <Container maxWidth="lg" style={{ paddingTop: 80 }}>
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />

          <Grid container spacing={5}>
            <Main title="From the firehose" />
            {/* <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
              social={sidebar.social}
            /> */}
          </Grid>
          {/* <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid> */}
          <br />
        </main>
      </Container>
      <Footer description="Be present, think about your future" />
    </ThemeProvider>
  );
}
