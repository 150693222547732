import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { background } from "../utils/colors";
import LogoWhite from "../images/logo white.png";

function Copyright() {
  return (
    <Typography variant="body2" align="center" style={{ fontSize: 13, marginTop: 20 }}>
      {"Copyright © "}
      <Link color="inherit" href="https://a-grid.com/">
        Agrid
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function Footer(props) {
  const { description, title } = props;

  return (
    <Box component="footer" sx={{ bgcolor: background, py: 6, color: "white" }}>
      <Container
        maxWidth="lg"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <img
          src={LogoWhite}
          style={{ objectFit: "contain", width: 200, marginBottom: 10 }}
          onClick={() => window?.open("https://a-grid.com")}
        />
        <Typography
          variant="subtitle1"
          align="center"
          component="p"
          style={{ width: 200, fontSize: 13 }}
        >
          {description}
        </Typography>
        <Copyright />
      </Container>
    </Box>
  );
}

Footer.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Footer;
